import React from "react"
import reelmp4 from "../assets/homepageMedia/reel.mp4"
import reelwebm from "../assets/homepageMedia/reel.webm"
import poster from "../assets/homepageMedia/poster.jpg"
import Typical from "react-typical"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const StyledTypical = styled(Typical)`
  font-size: 50px;
  position: absolute;
  text-align: center;
  color: white;
  @media only screen and (max-width: 500px) {
    font-size: 25px;
    white-space: pre-wrap;
    width: 230px;
  }
`

const StyledVideoContainer = styled.div`
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    height: 100vh;
  }
`

const StyledVideo = styled.video`
  @media only screen and (max-width: 500px) {
    margin-left: -25vw;
  }
`

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tarun Jangra Building Designers</title>
      </Helmet>
      <StyledVideoContainer>
        {/* <StyledTypical
          steps={["", 2000, "Build your dream.", 1000]}
          loop={Infinity}
          wrapper="p"
        /> */}
        <StyledVideo
          playsInline={true}
          autoPlay
          loop={true}
          muted
          poster={poster}
          id="bgvid"
        >
          <source src={reelmp4} type="video/mp4" />
          <source src={reelwebm} type="video/webm" />
        </StyledVideo>
      </StyledVideoContainer>
    </>
  )
}
